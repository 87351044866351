import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'

import WellnessPlanDetails from './details/WellnessPlanDetails'
import WellnessPlansTableComponent from './WellnessPlansTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 132,
      height: 40,
    },
  }),
  { name: 'WellnessPlansListPage' },
)

const WellnessPlansListPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { wellnessPlanVersionId } = useParams()
  const { t } = useTranslation('Common')

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN_CONFIG),
  )

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/wellness-plans'))
  }

  if (isMobile && typeof wellnessPlanVersionId !== 'undefined') {
    return (
      <WellnessPlanDetails
        itemId={wellnessPlanVersionId}
        onClose={onDetailsClose}
      />
    )
  }

  const headerButtons = (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.button}
        color="inherit"
        variant="extended"
        onClick={() => navigate('/admin/catalog/wellness-plans/create')}
      >
        {t('Common:NEW_VERSION')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <WellnessPlansTableComponent
        headerButtons={permissions.create && headerButtons}
        wellnessPlanVersionId={wellnessPlanVersionId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default WellnessPlansListPage
