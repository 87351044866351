import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Constant, Field } from '@pbt/pbt-ui-components'

import { CardPaymentMethod } from '~/api/graphql/generated/types'
import { GroupedOption } from '~/components/elements/OutlinedSelect/OutlinedSelect'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'

import AppointmentCancellationConsentMessage from './AppointmentCancellationConsentMessage'
import ChewyCardPaymentMethodDropdown from './ChewyCardPaymentMethodDropdown'

interface NoShowCancellationPenaltyContentProps {
  cardOnFileOrPaymentMethodMissingReasonField: Field
  cardPaymentMethodsAndMissingPaymentMethodReasons: GroupedOption<
    '' | CardPaymentMethodOrMissingReason
  >[]
  hasExternalPaymentMethod: boolean
  includeConsentMessageAlert: boolean
  isLoading: boolean
  numberOfPaymentMethods: number
  otherReasonField: Field
  paymentMethodOrMissingReasonValue: CardPaymentMethodOrMissingReason | ''
  setPaymentMethodOrMissingReasonValue: Dispatch<
    SetStateAction<CardPaymentMethod | Constant | '' | null>
  >
}

const NoShowCancellationPenaltyContent = ({
  cardOnFileOrPaymentMethodMissingReasonField,
  cardPaymentMethodsAndMissingPaymentMethodReasons,
  hasExternalPaymentMethod,
  isLoading,
  numberOfPaymentMethods,
  paymentMethodOrMissingReasonValue,
  setPaymentMethodOrMissingReasonValue,
  includeConsentMessageAlert,
  otherReasonField,
}: NoShowCancellationPenaltyContentProps) => {
  const handleChewyCardPaymentMethodOnlySelectChange = (
    value: SetStateAction<CardPaymentMethodOrMissingReason | '' | null>,
  ) => {
    cardOnFileOrPaymentMethodMissingReasonField.setValue(value)
    setPaymentMethodOrMissingReasonValue(value)
  }

  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isNoShowCancellationPenaltyCardOnFileAlertEnabled =
    useSelector(
      getFeatureToggle(
        FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
      ),
    ) && isOmnichannel

  const showPaymentSelectionDropdown =
    isNoShowCancellationPenaltyCardOnFileAlertEnabled &&
    hasExternalPaymentMethod

  if (isLoading) {
    return <></>
  }

  return (
    <>
      {includeConsentMessageAlert ? (
        <Grid item xs={12}>
          <AppointmentCancellationConsentMessage
            cardOnFileOrPaymentMethodMissingReasonField={
              cardOnFileOrPaymentMethodMissingReasonField
            }
            cardPaymentMethodsAndMissingPaymentMethodReasons={
              cardPaymentMethodsAndMissingPaymentMethodReasons
            }
            handleChewyCardPaymentMethodOnlySelectChange={
              handleChewyCardPaymentMethodOnlySelectChange
            }
            numberOfPaymentMethods={numberOfPaymentMethods}
            otherReasonField={otherReasonField}
            paymentMethodOrMissingReasonValue={
              paymentMethodOrMissingReasonValue
            }
          />
        </Grid>
      ) : (
        showPaymentSelectionDropdown && (
          <Grid item mt={2} xs={12}>
            <ChewyCardPaymentMethodDropdown
              cardOnFileOrPaymentMethodMissingReasonField={
                cardOnFileOrPaymentMethodMissingReasonField
              }
              cardPaymentMethodsAndMissingPaymentMethodReasons={
                cardPaymentMethodsAndMissingPaymentMethodReasons
              }
              handleChewyCardPaymentMethodOnlySelectChange={
                handleChewyCardPaymentMethodOnlySelectChange
              }
              otherReasonField={otherReasonField}
              paymentMethodOrMissingReasonValue={
                paymentMethodOrMissingReasonValue
              }
            />
          </Grid>
        )
      )}
    </>
  )
}

export default NoShowCancellationPenaltyContent
