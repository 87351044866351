import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { CustomFieldValidatorState, useFields } from '@pbt/pbt-ui-components'

import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'

import { VerifyAutoRenewingPlansTable } from './VerifyAutoRenewingPlansTable'

interface VerifyAutoRenewingPlansContainerProps {
  onValidityChange: (isValid: boolean) => void
  plansList: string[]
  plansMap: Record<string, any> // replace "any" in CVC-13486
  showAlert: boolean
  versionsList: string[]
  versionsMap: Record<string, any> // replace "any" in CVC-13486
}

export const VerifyAutoRenewingPlansContainer = ({
  plansMap,
  plansList,
  versionsList,
  versionsMap,
  onValidityChange,
  showAlert,
}: VerifyAutoRenewingPlansContainerProps) => {
  const { t } = useTranslation(['Dialogs', 'Common'])

  const activeVersion = versionsList.find(
    (id) => versionsMap[id]?.active,
  ) as string

  const activePlans = versionsMap[activeVersion].plans

  const planValidator = ({ value, name }: CustomFieldValidatorState) =>
    value === name || activePlans.includes(value)

  const { fields, validate } = useFields(
    plansList.map((planId: string) => ({
      name: planId,
      initialValue: plansMap[planId]?.autoRenewInto || planId,
      type: 'select',
      validators: [
        {
          validator: planValidator,
          validatorName: 'planValidator',
        },
      ],
    })),
  )

  const validateTable = () => {
    onValidityChange(validate())
  }

  useEffect(() => {
    validateTable()
  }, [])

  return (
    <>
      {showAlert && (
        <Box pb={1}>
          <AlertBanner
            title={t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.ALERT')}
            variant="error"
          />
        </Box>
      )}
      <VerifyAutoRenewingPlansTable
        fields={fields}
        plansMap={plansMap}
        versionsList={versionsList}
        versionsMap={versionsMap}
        onPlanSelect={validateTable}
      />
    </>
  )
}
