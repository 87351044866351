import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import * as R from 'ramda'
import {
  Constant,
  PermissionArea,
  RoleName,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'
import {
  Calendar as CalendarIcon,
  Communications as CommunicationsIcon,
  Estimates as EstimatesIcon,
  Print as PrintIcon,
  Sms as SmsIcon,
  Whiteboard as WhiteboardIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import AppointmentTypeLabel from '~/components/common/labels/AppointmentsTypeLabel'
import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import StatusSelectActionPopper from '~/components/dashboard/landing/widgets/StatusSelectActionPopper'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import { AppointmentState } from '~/constants/appointmentStates'
import { isAppointmentTypeWithNoShowCancellationPolicy } from '~/constants/appointmentTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import { BaseRoute } from '~/constants/routes'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import i18n from '~/locales/i18n'
import { patchAppointment } from '~/store/actions/timetable'
import { updateUsers } from '~/store/actions/users'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
} from '~/store/duck/landing'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getCRUDByArea,
  getCurrentBusinessId,
  getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getMultipleTimetableEvents,
  getTimetableIsDeleting,
  getTimetableIsLoading,
} from '~/store/reducers/timetable'
import {
  TimetableEvent,
  TimetableEventPersonResponsibility,
  TimetableEventPersonRole,
  WidgetColumn,
} from '~/types'
import { addOriginalBusinessId } from '~/utils'
import { getIsOutsideCancellationWindow } from '~/utils/appointmentCancellationUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useAppointmentStateId } from '~/utils/useEventType'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

import AppointmentDateCell from './AppointmentDateCell'

const getMemberName = (appointment: TimetableEvent) => {
  if (appointment?.personResponsibilities?.length) {
    const { personResponsibilities } = appointment
    if (personResponsibilities.length > 1) {
      const doctor = personResponsibilities!.find(
        (member: TimetableEventPersonResponsibility) =>
          member.responsibility?.name === ResponsibilityName.Veterinarian,
      )
      if (doctor) {
        return Utils.getPersonString(doctor.person)
      }
      Utils.getPersonString(personResponsibilities?.[0]?.person)
    }
    return Utils.getPersonString(personResponsibilities?.[0]?.person)
  }
  if (appointment?.personRoles?.length) {
    const { personRoles } = appointment
    if (personRoles.length > 1) {
      const doctor = personRoles!.find(
        (member: TimetableEventPersonRole) =>
          member.role?.name === ('Vet' as RoleName),
      )
      if (doctor) {
        return Utils.getPersonString(doctor.person)
      }
      Utils.getPersonString(personRoles?.[0]?.person)
    }
    return Utils.getPersonString(personRoles?.[0]?.person)
  }
  return ''
}

const getColumns = (isExpanded: boolean): WidgetColumn<TimetableEvent>[] =>
  [
    {
      id: 'name',
      component: AppointmentTypeLabel,
      width: isExpanded ? 2 : 3.5,
      label: i18n.t('Common:APPOINTMENT_TYPE'),
    },
    isExpanded && {
      id: 'notes',
      prop: (appointment: TimetableEvent) =>
        appointment?.notes ? appointment?.notes : '',
      width: 2,
      label: i18n.t('Common:NOTES'),
    },
    {
      id: 'team member',
      label: i18n.t('Common:TEAM_MEMBER'),
      prop: (appointment: TimetableEvent) => getMemberName(appointment),
      width: isExpanded ? 1.5 : 2.5,
    },
    {
      id: 'startDate',
      label: i18n.t('Common:DATE_AND_TIME'),
      component: AppointmentDateCell,
      width: isExpanded ? 2.5 : 2.5,
    },
    {
      id: 'status',
      label: i18n.t('Common:STATUS'),
      prop: (appointment: TimetableEvent) => (
        <AppointmentStateLabel item={appointment} />
      ),
      width: isExpanded ? 1.5 : 2,
    },
  ].filter(Boolean) as WidgetColumn[]

interface AppointmentsSnapshotProps
  extends Omit<LandingWidgetProps<TimetableEvent>, 'component' | 'data'> {
  clientId: string
  expandedSnapshotType?: SnapshotsAliasTypes
  name?: string
  onExpand: () => void
  patientId: string
}

const AppointmentsSnapshot = ({
  name,
  clientId,
  patientId,
  onExpand,
  expandedSnapshotType,
  rowsCount,
  ...rest
}: AppointmentsSnapshotProps) => {
  const { t } = useTranslation(['Common', 'Clients'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPrintInvoiceDialog] = useDialog(DialogNames.PRINT_INVOICE)
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openPrintFolderLabelDialog] = useDialog(DialogNames.PRINT_FOLDER_LABEL)
  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openAppointmentCancellationReasonDialog] = useDialog(
    DialogNames.APPOINTMENT_CANCELLATION_REASON,
  )

  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )
  const isCurrentBusinessIsAppointmentCancellationReasonEnabled = useSelector(
    getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  )

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const appointmentsIds = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Appointments,
    ),
  )
  const appointments = useSelector(getMultipleTimetableEvents(appointmentsIds))
  const isDymoConnectEnabled = useIsDymoConnectEnabled()
  const widgetsIsLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Appointments,
    ),
  )
  const appointmentsIsLoading = useSelector(getTimetableIsLoading)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const {
    create: appointmentCreatePermissions,
    read: appointmentReadPermissions,
    update: appointmentUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.EVENT_APPOINTMENT))
  const { read: invoiceReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const {
    create: communicationCreatePermissions,
    read: communicationReadPermissions,
  } = useSelector(getCRUDByArea(PermissionArea.COMMUNICATION))
  const { create: soapCreatePermissions, read: soapReadPermissions } =
    useSelector(getCRUDByArea(PermissionArea.SOAP))
  const { read: patientReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const [columns, setColumns] = useState<WidgetColumn<TimetableEvent>[]>([])

  const visibleAppointments = appointments.filter(Boolean).slice(0, rowsCount)
  const isLoading = widgetsIsLoading || appointmentsIsLoading

  const handleNavigateToSoap = (item?: TimetableEvent) => {
    const originalBusinessId = isPatientSharingEnabled ? item?.businessId : null
    if (item?.soapId) {
      navigate(
        addOriginalBusinessId(`/soap/${item.soapId}`, originalBusinessId),
      )
    } else {
      navigate(
        addOriginalBusinessId(`/soap/create/${item?.id}`, originalBusinessId),
      )
    }
  }
  const refreshAppointmentWidgets = () => {
    dispatch(
      fetchWidgetsData([SnapshotsAliasTypes.Appointments], {
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        quiet: false,
        patientId,
      }),
    )
  }
  const refreshAfterSave = useCloseAfterCreation(
    refreshAppointmentWidgets,
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Appointments,
    ),
  )

  const refreshAfterEventDeleting = useCloseAfterCreation(
    refreshAppointmentWidgets,
    getTimetableIsDeleting,
    true,
  )

  const isNoShowCancellationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY),
  )

  const isNoShowCancellationPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )

  const isOmnichannelBusiness = useSelector(getCurrentBusinessIsOmniChannel)

  const isNotInWindowAndIsSupportedAppointmentType = (item: TimetableEvent) =>
    getIsOutsideCancellationWindow(item.scheduledStartDatetime) &&
    isAppointmentTypeWithNoShowCancellationPolicy(
      item.businessAppointmentType?.name || '',
    )

  const showNoShowCancellationPenaltyAlert =
    isNoShowCancellationEnabled &&
    isNoShowCancellationPenaltyCardOnFileAlertEnabled &&
    isOmnichannelBusiness

  const handleStatusChange = (value: Constant, item?: TimetableEvent) => {
    if (item) {
      if (
        isAppointmentCancellationReasonEnabled &&
        isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
        value.id === cancelledStateId
      ) {
        openAppointmentCancellationReasonDialog({
          onProceed: (
            cancellationReasonId: string,
            cancellationReason: string,
          ) => {
            dispatch(
              patchAppointment({
                id: item.id,
                state: value.id,
                cancellationReasonId,
                cancellationReason,
              }),
            )
            refreshAfterSave()
          },
          showNoShowCancellationPenaltyAlert:
            showNoShowCancellationPenaltyAlert &&
            isNotInWindowAndIsSupportedAppointmentType(item),
        })
      } else {
        dispatch(patchAppointment({ id: item.id, state: value.id }))
        refreshAfterSave()
      }
    }
  }

  const goToAppointment = (item?: TimetableEvent) => {
    if (!R.isEmpty(item?.personResponsibilities)) {
      const journal = R.mergeAll(
        item?.personResponsibilities?.map((teamMember) => ({
          [teamMember.personId]: teamMember.person,
        })) || [],
      )
      dispatch(updateUsers(journal))
    } else if (!R.isEmpty(item?.personRoles)) {
      const journal = R.mergeAll(
        item?.personRoles?.map((teamMember) => ({
          [teamMember.personId]: teamMember.person,
        })) || [],
      )
      dispatch(updateUsers(journal))
    }
    openAppointmentDialog({ clientId, patientId, appointmentId: item?.id })
    refreshAfterEventDeleting()
  }

  const onHandleAddAppointment = () => {
    navigate('/scheduler', { state: { clientId, patientId } })
  }

  const handleOpenInvoice = (item: TimetableEvent) =>
    openInvoice({
      clientId: item.clientId,
      patientId: item.patientId,
      soapId: item.soapId,
      invoiceId: item.invoiceId,
    })

  const handlePrintInvoice = (item: TimetableEvent) => {
    handleOpenInvoice(item)
    openPrintInvoiceDialog({
      invoiceIds: item?.invoiceId ? [item.invoiceId] : [],
    })
  }

  const handlePrintCageCard = () =>
    openPrintCageLabelDialog({ clientId, patientId })

  const handlePrintFolderLabel = () =>
    openPrintFolderLabelDialog({ clientId, patientId })

  const navigateToConversation = (id?: string) => {
    if (id) {
      navigate(`${BaseRoute.COMMUNICATIONS}/${id}`)
    }
  }

  const handleCreateNewConversation = (item?: TimetableEvent) => {
    openNewConversationDialog({ clientId, patientId, appointmentId: item?.id })
  }

  const handleOpenConversation = ({ conversationId }: TimetableEvent) =>
    navigateToConversation(conversationId)

  const getActions = (item?: TimetableEvent) => {
    const isCurrentContextItem =
      !isPatientSharingEnabled || item?.businessId === currentBusinessId

    return [
      {
        id: 'soap',
        isGroup: true,
        items: [
          item?.soapId
            ? {
                id: 'goToSoap',
                label: t('Common:GO_TO_SOAP'),
                Icon: ArrowForwardIcon,
                onClick: soapReadPermissions
                  ? () => handleNavigateToSoap(item)
                  : undefined,
                disabled: !soapReadPermissions,
              }
            : isCurrentContextItem && {
                id: 'createSoap',
                label: t('Common:CREATE_SOAP'),
                Icon: ArrowForwardIcon,
                onClick: soapCreatePermissions
                  ? () => handleNavigateToSoap(item)
                  : undefined,
                disabled: !soapCreatePermissions,
              },
          isCurrentContextItem && {
            id: 'goToAppointment',
            label: t('Common:GO_TO_APPOINTMENT'),
            Icon: CalendarIcon,
            onClick: appointmentReadPermissions
              ? () => goToAppointment(item)
              : undefined,
            disabled: !appointmentReadPermissions,
          },
          isCurrentContextItem && {
            id: 'appointmentStatus',
            label: t('Common:APPOINTMENT_STATUS'),
            Icon: WhiteboardIcon,
            subItem: StatusSelectActionPopper,
            onClick: appointmentUpdatePermissions
              ? (value: Constant) => handleStatusChange(value, item)
              : undefined,
            disabled: !appointmentUpdatePermissions,
          },
        ],
      },
      isCurrentContextItem &&
        item?.invoiceId && {
          id: 'invoice',
          title: t('Common:INVOICE'),
          isGroup: true,
          items: [
            {
              id: 'openInvoice',
              label: t('Common:OPEN_INVOICE'),
              Icon: EstimatesIcon,
              onClick: invoiceReadPermissions
                ? () => handleOpenInvoice(item)
                : undefined,
              disabled: !invoiceReadPermissions,
            },
            {
              id: 'printInvoice',
              label: t('Common:PRINT_INVOICE'),
              Icon: PrintIcon,
              onClick: invoiceReadPermissions
                ? () => handlePrintInvoice(item)
                : undefined,
              disabled: !invoiceReadPermissions,
            },
          ],
        },
      {
        id: 'patient',
        title: t('Common:PATIENT'),
        isGroup: true,
        items: [
          {
            id: 'printCageCardLabel',
            label: t('Common:PRINT_CAGE_CARD_LABEL'),
            Icon: PrintIcon,
            onClick: patientReadPermissions
              ? () => handlePrintCageCard()
              : undefined,
            disabled: !patientReadPermissions,
          },
          isDymoConnectEnabled && {
            id: 'printFolderLabel',
            label: t('Common:PRINT_FOLDER_LABEL'),
            Icon: PrintIcon,
            onClick: patientReadPermissions
              ? () => handlePrintFolderLabel()
              : undefined,
            disabled: !patientReadPermissions,
          },
        ],
      },
      {
        id: 'client',
        title: t('Common:CLIENT'),
        isGroup: true,
        items: [
          item?.conversationId
            ? {
                id: 'openConversation',
                label: t('Common:OPEN_CONVERSATION'),
                Icon: SmsIcon,
                onClick: communicationReadPermissions
                  ? () => handleOpenConversation(item)
                  : undefined,
                disabled: !communicationReadPermissions,
              }
            : {
                id: 'newConversation',
                label: t('Common:NEW_CLIENT_CONVERSATION'),
                Icon: CommunicationsIcon,
                onClick: communicationCreatePermissions
                  ? () => handleCreateNewConversation(item)
                  : undefined,
                disabled: !communicationCreatePermissions,
              },
        ],
      },
    ] as PopperAction[]
  }

  useEffect(() => {
    const isExpanded = expandedSnapshotType === SnapshotsAliasTypes.Appointments
    setColumns(getColumns(isExpanded))
  }, [expandedSnapshotType])

  return (
    <LandingWidget
      showHeaderTitles
      canNavigateToDetails={false}
      canNavigateToItemDetails={(item) => {
        const isCurrentContextItem =
          !isPatientSharingEnabled || item?.businessId === currentBusinessId
        // The soap navigation with creation flow is forbidden for shared appointments without soap
        return Boolean(item) && (isCurrentContextItem || item.soapId)
      }}
      columns={columns}
      component={TableWidget}
      data={visibleAppointments}
      getActions={getActions}
      isLoading={isLoading}
      name={name}
      navigateToItemDetailsTooltip={t('Common:GO_TO_SOAP')}
      noRecords={
        <NoRecords
          action={t('Common:ADD_APPOINTMENT')}
          text={t('Common:NO_APPOINTMENTS')}
          onClick={
            appointmentCreatePermissions ? onHandleAddAppointment : undefined
          }
        />
      }
      rowsCount={rowsCount}
      showPaddings={false}
      onExpand={onExpand}
      onNavigateToItemDetails={handleNavigateToSoap}
      {...rest}
    />
  )
}

export default AppointmentsSnapshot
