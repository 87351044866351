import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import {
  clearAutoRenewingPlansInfo,
  fetchAutoRenewingPlansInfo,
} from '~/store/actions/wellnessPlans'
import {
  getAutoRenewingPlansInfo,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'

import { VerifyAutoRenewingPlansContainer } from './VerifyAutoRenewingPlansContainer'

interface VerifyAutoRenewingPlansDialogProps extends BasePuiDialogProps {}

export const VerifyAutoRenewingPlansDialog = ({
  open,
  onClose,
}: VerifyAutoRenewingPlansDialogProps) => {
  const [isValid, setIsValid] = useState(true)
  const { t } = useTranslation(['Dialogs', 'Common'])
  const dispatch = useDispatch()
  const isLoading = useSelector(getWellnessPlansIsLoading)
  const autoRenewingPlansInfo = useSelector(getAutoRenewingPlansInfo)

  const showProgress =
    isLoading ||
    !autoRenewingPlansInfo?.plansMap ||
    !autoRenewingPlansInfo?.versionsList ||
    !autoRenewingPlansInfo?.versionsMap

  useEffect(() => {
    dispatch(fetchAutoRenewingPlansInfo())
    return () => {
      dispatch(clearAutoRenewingPlansInfo())
    }
  }, [])

  return (
    <PuiDialog
      fullWidth
      actions={
        <Box width={120}>
          <ButtonWithLoader
            fullWidth
            disabled={!isValid}
            loading={false}
            onClick={() => {}}
          >
            {t('Common:UPDATE_ACTION')}
          </ButtonWithLoader>
        </Box>
      }
      maxWidth="lg"
      open={open}
      title={t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <CircularProgressOverlay open={showProgress} />
      <Box sx={{ minHeight: 300, padding: 2 }}>
        {!showProgress && (
          <VerifyAutoRenewingPlansContainer
            plansList={autoRenewingPlansInfo.plansList}
            plansMap={autoRenewingPlansInfo.plansMap}
            showAlert={!isValid}
            versionsList={autoRenewingPlansInfo.versionsList}
            versionsMap={autoRenewingPlansInfo.versionsMap}
            onValidityChange={(isTableValid: boolean) =>
              setIsValid(isTableValid)
            }
          />
        )}
      </Box>
    </PuiDialog>
  )
}
