import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  PermissionArea,
  Text,
  useFields,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import {
  useGetPlanDefaultName,
  useGetPlanRequiredBenefitsCount,
  useValidatePlanBenefits,
} from '~/store/hooks/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandleWithUnsavedChanges } from '~/types'
import {
  createDeepEqualityComparator,
  isFieldValuesChanged,
  normalizeInputAsString,
} from '~/utils'
import { useBoopFilteredBenefits } from '~/utils/boop'

import WellnessPlanPriceLabel from '../steps/plan-review/WellnessPlanPriceLabel'
import { WellnessPlanPriceTypeControlHandle } from '../WellnessPlanPriceTypeControl'
import { getUpdatedPlan, splitBenefits } from '../wellnessPlanUtils'
import { WellnessPlanLevelsDetails } from './item/WellnessPlanLevelsDetails'

const useStyles = makeStyles(
  (theme) => ({
    accordionRoot: {
      marginTop: theme.spacing(1),
      width: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
      boxShadow: 'none',
      backgroundColor: theme.colors.tableBackground,
      '&:before': {
        backgroundColor: 'transparent',
      },
    },
    expanded: {},
    accordionSummary: {
      flexDirection: 'row-reverse',
      height: 40,
      minHeight: 40,
      '&&$expanded': {
        minHeight: 40,
      },
      padding: theme.spacing(0, 1),
    },
    accordionSummaryContent: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    expandIcon: {
      color: theme.colors.primaryText,
      marginRight: theme.spacing(1),
      padding: 0,
      fontSize: '2.5rem',
      '&&$expanded': {
        transform: 'none',
      },
    },
    expandIconExpanded: {
      color: theme.colors.title,
    },
    panelExpanded: {
      border: theme.constants.activeFieldBorder,
    },
    heading: {
      marginRight: theme.spacing(0.5),
      fontSize: '1.6rem',
    },
    headingExpanded: {
      color: theme.colors.title,
    },
    table: {
      marginTop: theme.spacing(2),
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
  }),
  { name: 'WellnessPlanLevelsDetailsSectionItem' },
)

const compareWellnessPlansBenefits = createDeepEqualityComparator({
  propertyPickers: {
    benefits: R.pipe(
      R.sortBy<WellnessPlanBenefit>((benefit) => benefit.id || ''),
      R.map((benefit: WellnessPlanBenefit) => ({
        ...benefit,
        description: normalizeInputAsString(benefit.description),
      })),
    ),
  },
})

export interface WellnessPlanLevelsDetailsSectionItemHandle
  extends DataHandleWithUnsavedChanges<WellnessPlan | undefined> {}

interface WellnessPlanLevelsDetailsSectionItemProps {
  onDeletePlan: (plan: WellnessPlan) => void
  wellnessPlan: WellnessPlan
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanLevelsDetailsSectionItem = forwardRef<
  WellnessPlanLevelsDetailsSectionItemHandle,
  WellnessPlanLevelsDetailsSectionItemProps
>(function WellnessPlanLevelsDetailsSectionItem(
  { wellnessPlan, wellnessPlanVersion, onDeletePlan },
  ref,
) {
  const classes = useStyles()

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN_CONFIG),
  )

  const planDefaultName = useGetPlanDefaultName(wellnessPlan)
  const requiredBenefitCount = useGetPlanRequiredBenefitsCount(wellnessPlan)

  const [expanded, setExpanded] = useState(false)
  const [planCandidate, setPlanCandidate] = useState(wellnessPlan)
  const [hasCoverItemsValidationError, setHasCoverItemsValidationError] =
    useState(false)

  const priceTypeControlRef = useRef<WellnessPlanPriceTypeControlHandle>(null)

  const { activePatients = 0 } = wellnessPlanVersion
  const hasActivePatients = activePatients > 0
  const disableEdit = !permissions.update || hasActivePatients
  const {
    level,
    benefits: benefitsFromPlan = [],
    name: planName,
  } = planCandidate || {}
  const benefits = useBoopFilteredBenefits(benefitsFromPlan)
  const otherBenefits = splitBenefits(benefits)[1]
  const hasEnoughBenefits = otherBenefits.length > requiredBenefitCount
  const isBaseLevel = level === WellnessPlanLevels.BASE

  const validatePlanBenefits = useValidatePlanBenefits()

  const { fields, validate, reset } = useFields(
    [
      { name: 'name', initialValue: wellnessPlan?.name || '' },
      {
        name: 'price',
        validators: ['nonNegative'],
        initialValue: wellnessPlan?.price,
      },
    ],
    false,
  )

  const { name, price } = fields

  useEffect(() => {
    setPlanCandidate(wellnessPlan)
    reset()
  }, [wellnessPlan])

  // We should start avoid using this hook. That's because we lose refs whenever we render conditionally its child.
  // The downside here is that we MUST NOT use TransitionProps or render details component conditionally with expanded state.
  // The approaches above would improve our performance, but it would break our component
  useImperativeHandle(ref, () => ({
    validate: () => {
      const fieldsValid = validate()
      const priceTypeControlValid =
        priceTypeControlRef.current?.validate?.() ?? true

      if (!fieldsValid || !priceTypeControlValid) {
        return false
      }

      const benefitsValid = validatePlanBenefits(planCandidate)

      setHasCoverItemsValidationError(!benefitsValid)
      if (!benefitsValid) {
        setExpanded(true)
      }

      return benefitsValid
    },
    get: () => {
      if (!planCandidate) {
        return undefined
      }

      return getUpdatedPlan(
        planCandidate,
        name,
        isBaseLevel,
        priceTypeControlRef,
      )
    },
    hasUnsavedChanges: () => {
      const hasChangesInFields = isFieldValuesChanged(fields)
      const hasChangesInPriceTypeControl =
        priceTypeControlRef.current?.hasUnsavedChanges() ?? false
      const hasChangesInBenefits = !compareWellnessPlansBenefits(
        planCandidate,
        wellnessPlan,
      )

      return (
        hasChangesInFields ||
        hasChangesInPriceTypeControl ||
        hasChangesInBenefits
      )
    },
  }))

  if (!planCandidate) {
    return null
  }

  return (
    <Accordion
      classes={{
        root: classes.accordionRoot,
        expanded: classes.panelExpanded,
      }}
      expanded={expanded}
      key={planCandidate.level}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        classes={{
          content: classes.accordionSummaryContent,
          root: classes.accordionSummary,
          expanded: classes.expanded,
          expandIconWrapper: classNames(classes.expandIcon, {
            [classes.expandIconExpanded]: expanded,
          }),
        }}
        expandIcon={expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
      >
        <Grid container item alignItems="center" wrap="nowrap">
          <Text
            className={classNames(classes.heading, {
              [classes.headingExpanded]: expanded,
            })}
            variant="h2"
          >
            {`${planDefaultName}${
              planName && planName !== planDefaultName ? `: ${planName}` : ''
            }`}
            {' | '}
          </Text>
          <WellnessPlanPriceLabel
            className={classNames(classes.heading, {
              [classes.headingExpanded]: expanded,
            })}
            display="inline"
            plan={wellnessPlan}
            variant="h2"
          />
        </Grid>
        {!disableEdit && (
          <IconButton
            aria-label="delete plan"
            size="large"
            onClick={(event) => {
              event.stopPropagation()
              onDeletePlan(wellnessPlan)
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <WellnessPlanLevelsDetails
          benefits={benefits}
          disableEdit={disableEdit}
          hasActivePatients={hasActivePatients}
          hasCoverItemsValidationError={hasCoverItemsValidationError}
          hasEnoughBenefits={hasEnoughBenefits}
          isBaseLevel={isBaseLevel}
          name={name}
          planCandidate={planCandidate}
          planDefaultName={planDefaultName}
          price={price}
          priceTypeControlRef={priceTypeControlRef}
          setHasCoverItemsValidationError={setHasCoverItemsValidationError}
          setPlanCandidate={setPlanCandidate}
          wellnessPlan={wellnessPlan}
          wellnessPlanVersion={wellnessPlanVersion}
        />
      </AccordionDetails>
    </Accordion>
  )
})

export default WellnessPlanLevelsDetailsSectionItem
