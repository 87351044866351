import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox, PuiSelect, useFields } from '@pbt/pbt-ui-components'

import { CancellationInfo } from '~/constants/cancellationReasonType'
import { getInternalCancellationReasons } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    checkbox: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  }),
  { name: 'Appointment' },
)
export interface AppointmentCancellationDetailsProps {
  cancellationInfo: CancellationInfo
  waiveLateCancellationFeeChecked: boolean
}
export const AppointmentCancellationDetails = ({
  cancellationInfo,
  waiveLateCancellationFeeChecked,
}: AppointmentCancellationDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])
  const appointmentCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const { fields } = useFields(
    [
      {
        name: 'cancellationReason',
        label: t('Common:CANCELLATION_REASON'),
        initialValue: cancellationInfo.cancellationReasonId || '',
      },
      {
        name: 'waiveLateCancellationFee',
        label: t('Common:WAIVE_LATE_CANCELLATION_FEE'),
        initialValue: waiveLateCancellationFeeChecked || false,
      },
    ],
    false,
  )

  const { cancellationReason, waiveLateCancellationFee } = fields
  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="cancellation-reason-select">
            {t('Common:CANCELLATION_REASON')}
          </InputLabel>
          <PuiSelect
            field={cancellationReason}
            items={appointmentCancellationReasons}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.checkbox} xs={6}>
        <PuiCheckbox
          checked={waiveLateCancellationFee.value}
          label={t('Common:WAIVE_LATE_CANCELLATION_FEE')}
          onChange={(event) =>
            waiveLateCancellationFee.setValue(event.target.checked)
          }
        />
      </Grid>
    </Grid>
  )
}
