import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Field, PuiTextField } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    outlineTextColor: {
      backgroundColor: theme.colors.grayWhite,
    },
  }),
  { name: 'OtherMissingPaymentMethodReasonTextField' },
)

interface OtherMissingPaymentMethodReasonTextFieldProps {
  otherReasonField: Field
  shouldAutoFocus: boolean
}

const OtherMissingPaymentMethodReasonTextField = ({
  otherReasonField,
  shouldAutoFocus,
}: OtherMissingPaymentMethodReasonTextFieldProps) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()

  const [otherReasonFieldValue, setOtherReasonFieldValue] = useState(
    otherReasonField?.initialValue || '',
  )

  const handleOtherReasonTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    otherReasonField.setValue(event.target.value)
    setOtherReasonFieldValue(event.target.value)
  }

  return (
    <PuiTextField
      focused
      required
      autoFocus={shouldAutoFocus}
      className={classes.outlineTextColor}
      field={otherReasonField}
      label={t('Common:WHY_WAS_NO_CARD_CHOSEN')}
      value={otherReasonFieldValue}
      onChange={handleOtherReasonTextFieldChange}
    />
  )
}

export default OtherMissingPaymentMethodReasonTextField
