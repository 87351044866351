import { keys, union } from 'ramda'
import { FieldObject } from '@pbt/pbt-ui-components'

import { isNilOrEmpty } from '~/utils'

import { Credentials } from './integration/imaging/vendors/IdexxImagingForm'

export const IMPORT_INVENTORY_STATUS = {
  FAILED: 'FAILED',
  SUCCEED: 'SUCCEED',
  IN_PROGRESS: 'IN_PROGRESS',
  IS_NOT_STARTED: 'IS_NOT_STARTED',
}

export function getPropertiesMapFromFields(fields: FieldObject) {
  return Object.entries(fields).reduce(
    (acc: Record<string, any>, [name, { value }]) => {
      acc[name] = value
      return acc
    },
    {},
  )
}

export function getFieldsValuesList(fields: FieldObject) {
  return Object.values(fields).map(({ value }) => value)
}

export function hasCredentialsChanged(
  oldCredentials: Partial<Credentials>,
  newCredentials: Partial<Credentials>,
) {
  const allKeys = union(keys(oldCredentials), keys(newCredentials))
  return allKeys.reduce((hasChanges, key) => {
    const oldValue = oldCredentials[key]
    const newValue = newCredentials[key]
    return (
      hasChanges ||
      (oldValue !== newValue &&
        !(isNilOrEmpty(oldValue) && isNilOrEmpty(newValue)))
    )
  }, false)
}
