import { findByProp } from '@pbt/pbt-ui-components/src/utils'

import { Price } from '~/api/graphql/generated/types'

export const MISCELLANEOUS_PROCEDURE_INTERNAL_CODE = '57497'

export enum MiscellanousProcedureOriginalCodes {
  LATE_CANCELLATION_FEE = '57497-8',
  MISSED_APPOINTMENT_FEE = '57497-9',
}

export const getPenaltyFeeByOriginalCode = (
  prices: Partial<Price>[] | undefined,
  originalCode: MiscellanousProcedureOriginalCodes,
): number | null =>
  findByProp(originalCode, prices, 'originalCode')?.price || null
