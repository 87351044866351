import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ErrorTooltip, Field, PuiCheckbox } from '@pbt/pbt-ui-components'

import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import { GroupedOption } from '~/components/elements/OutlinedSelect/OutlinedSelect'
import Typography from '~/components/elements/Typography/Typography'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'

import CancellationPolicyText from './CancellationPolicy'
import ChewyCardPaymentMethodDropdown from './ChewyCardPaymentMethodDropdown'

const useStyles = makeStyles(
  (theme) => ({
    warningText: {
      color: theme.colors.important,
    },
    hasError: {
      color: theme.colors.errorColor,
    },
    consentTooltip: {
      maxWidth: 500,
      width: 500,
    },
    cardOnFileTooltip: {
      maxWidth: 550,
      width: 550,
    },
  }),
  { name: 'AppointmentCancellationConsentMessage' },
)

export interface AppointmentCancellationConsentMessageProps {
  cardOnFileOrPaymentMethodMissingReasonField: Field
  cardPaymentMethodsAndMissingPaymentMethodReasons: GroupedOption<
    '' | CardPaymentMethodOrMissingReason
  >[]
  handleChewyCardPaymentMethodOnlySelectChange: (
    value: SetStateAction<CardPaymentMethodOrMissingReason | '' | null>,
  ) => void
  numberOfPaymentMethods: number
  otherReasonField: Field
  paymentMethodOrMissingReasonValue: CardPaymentMethodOrMissingReason | ''
}

const AppointmentCancellationConsentMessage = ({
  cardOnFileOrPaymentMethodMissingReasonField,
  otherReasonField,
  cardPaymentMethodsAndMissingPaymentMethodReasons,
  numberOfPaymentMethods,
  paymentMethodOrMissingReasonValue,
  handleChewyCardPaymentMethodOnlySelectChange,
}: AppointmentCancellationConsentMessageProps) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()
  const hasCardOnFileOrReasonMissingError =
    cardOnFileOrPaymentMethodMissingReasonField.errors.length > 0 &&
    cardOnFileOrPaymentMethodMissingReasonField.open

  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isNoShowCancellationPenaltyCardOnFileAlertEnabled =
    useSelector(
      getFeatureToggle(
        FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
      ),
    ) && isOmnichannel

  return (
    <ErrorTooltip
      className={
        numberOfPaymentMethods === 0
          ? classes.consentTooltip
          : classes.cardOnFileTooltip
      }
      classes={{
        errorTooltip:
          numberOfPaymentMethods === 0
            ? classes.consentTooltip
            : classes.cardOnFileTooltip,
      }}
      message={cardOnFileOrPaymentMethodMissingReasonField.message}
      open={hasCardOnFileOrReasonMissingError}
      placement="bottom"
    >
      <FormControl
        fullWidth
        error={!cardOnFileOrPaymentMethodMissingReasonField.value}
        margin="normal"
      >
        <AlertBanner
          ContainerProps={{ mt: 1, p: 0.5 }}
          content={
            <>
              {!isNoShowCancellationPenaltyCardOnFileAlertEnabled ||
                (numberOfPaymentMethods !== 0 && (
                  <Typography.H2 className={classes.warningText}>
                    {t('Common:CHOOSE_CARD_TO_HOLD_APPOINTMENT')}
                  </Typography.H2>
                ))}
              <CancellationPolicyText isCancelDialog={false} />
              {!isNoShowCancellationPenaltyCardOnFileAlertEnabled ||
              numberOfPaymentMethods === 0 ? (
                <PuiCheckbox
                  checkboxClasses={{
                    root: classNames({
                      [classes.hasError]: hasCardOnFileOrReasonMissingError,
                    }),
                  }}
                  checked={cardOnFileOrPaymentMethodMissingReasonField.value}
                  label={t('Common:PET_PARENT_AWARE_OF_CANCELLATION_POLICY')}
                  onChange={(event) =>
                    cardOnFileOrPaymentMethodMissingReasonField.setValue(
                      event.target.checked,
                    )
                  }
                />
              ) : (
                <ChewyCardPaymentMethodDropdown
                  cardOnFileOrPaymentMethodMissingReasonField={
                    cardOnFileOrPaymentMethodMissingReasonField
                  }
                  cardPaymentMethodsAndMissingPaymentMethodReasons={
                    cardPaymentMethodsAndMissingPaymentMethodReasons
                  }
                  handleChewyCardPaymentMethodOnlySelectChange={
                    handleChewyCardPaymentMethodOnlySelectChange
                  }
                  otherReasonField={otherReasonField}
                  paymentMethodOrMissingReasonValue={
                    paymentMethodOrMissingReasonValue
                  }
                />
              )}
            </>
          }
          variant="warning"
        />
      </FormControl>
    </ErrorTooltip>
  )
}

export default AppointmentCancellationConsentMessage
