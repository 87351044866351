import * as R from 'ramda'

export enum CardPaymentMethodOrMissingReasonType {
  CARD_PAYMENT_METHOD = 'CardPaymentMethod',
  CLIENT_DECLINED = 'Client Declined',
  CLIENT_USE_DIFFERENT_CARD = 'Client Use Different Card',
  URGENT = 'Urgent',
  OTHER = 'Other',
}

export const MISSING_PAYMENT_METHOD_REASON_TYPE_NAMES = [
  CardPaymentMethodOrMissingReasonType.CLIENT_DECLINED,
  CardPaymentMethodOrMissingReasonType.CLIENT_USE_DIFFERENT_CARD,
  CardPaymentMethodOrMissingReasonType.URGENT,
  CardPaymentMethodOrMissingReasonType.OTHER,
]

export const isCardPaymentMethod = (
  cardPaymentMethodOrMissingReason:
    | string
    | CardPaymentMethodOrMissingReasonType,
) =>
  cardPaymentMethodOrMissingReason ===
  CardPaymentMethodOrMissingReasonType.CARD_PAYMENT_METHOD

export const isMissingPaymentMethodReason = (
  cardPaymentMethodOrMissingReason:
    | string
    | CardPaymentMethodOrMissingReasonType,
) =>
  R.any(
    (missingPaymentMethodReason) =>
      missingPaymentMethodReason === cardPaymentMethodOrMissingReason,
    MISSING_PAYMENT_METHOD_REASON_TYPE_NAMES,
  )

export const isOtherMissingPaymentMethodReason = (
  cardPaymentMethodOrMissingReason:
    | string
    | CardPaymentMethodOrMissingReasonType,
) =>
  cardPaymentMethodOrMissingReason ===
  CardPaymentMethodOrMissingReasonType.OTHER
