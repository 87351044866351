import React, { SetStateAction } from 'react'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from '@pbt/pbt-ui-components'

import { GroupedOption } from '~/components/elements/OutlinedSelect/OutlinedSelect'
import { isOtherMissingPaymentMethodReason } from '~/constants/cardPaymentMethodOrMissingReasonTypes'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'

import { ChewyCardPaymentMethodOnlySelect } from './ChewyCardPaymentMethodOnlySelect'
import OtherMissingPaymentMethodReasonTextField from './OtherMissingPaymentMethodReasonTextField'

const StyledSelectContainerStack = styled(Stack)`
  align-items: flex-start;

  & .MuiFormControl-root {
    max-width: auto;
    width: 100%;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

interface ChewyCardPaymentMethodDropdownProps {
  cardOnFileOrPaymentMethodMissingReasonField: Field
  cardPaymentMethodsAndMissingPaymentMethodReasons: GroupedOption<
    '' | CardPaymentMethodOrMissingReason
  >[]
  handleChewyCardPaymentMethodOnlySelectChange: (
    value: SetStateAction<CardPaymentMethodOrMissingReason | '' | null>,
  ) => void
  otherReasonField: Field
  paymentMethodOrMissingReasonValue: CardPaymentMethodOrMissingReason | ''
}

const ChewyCardPaymentMethodDropdown = ({
  cardPaymentMethodsAndMissingPaymentMethodReasons,
  cardOnFileOrPaymentMethodMissingReasonField,
  handleChewyCardPaymentMethodOnlySelectChange,
  otherReasonField,
  paymentMethodOrMissingReasonValue,
}: ChewyCardPaymentMethodDropdownProps) => {
  const showOtherReasonField =
    isOtherMissingPaymentMethodReason(
      cardOnFileOrPaymentMethodMissingReasonField?.value?.name,
    ) ||
    isOtherMissingPaymentMethodReason(
      cardOnFileOrPaymentMethodMissingReasonField?.initialValue?.name,
    )

  const isOtherReasonSelected = isOtherMissingPaymentMethodReason(
    cardOnFileOrPaymentMethodMissingReasonField?.value?.name,
  )

  return (
    <StyledSelectContainerStack direction="column">
      <ChewyCardPaymentMethodOnlySelect
        borderlessWhenDisabled
        groupedOptions={cardPaymentMethodsAndMissingPaymentMethodReasons}
        value={paymentMethodOrMissingReasonValue}
        onChange={handleChewyCardPaymentMethodOnlySelectChange}
      />
      {showOtherReasonField && (
        <OtherMissingPaymentMethodReasonTextField
          otherReasonField={otherReasonField}
          shouldAutoFocus={isOtherReasonSelected}
        />
      )}
    </StyledSelectContainerStack>
  )
}

export default ChewyCardPaymentMethodDropdown
